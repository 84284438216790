<template>
  <div>
    <p class="technologies-name mb-3">
      {{ $t("heading") }}
    </p>

    <div class="technologies mb-3 d-flex flex-wrap w-100">
      <div
        v-for="(technology, index) in technologies"
        :key="index"
        class="technologies-item d-flex justify-content-center"
      >
        <div class="technologies-block d-flex">
          <div class="technologies-image">
            <img class="d-block" :src="technology.src" :alt="technology.alt" />
          </div>
          <div class="technologies-title w-100 d-flex align-items-center">
            <span class="d-block w-100">{{ technology.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoryTechnologies",
  data() {
    return {
      technologies: this.technologiesProps
    };
  },
  props: {
    technologiesProps: {
      type: Array,
      required: true
    }
  },
  i18n: {
    messages: {
      de: {
        heading: `Technologie-Stack im Überblick:`
      },
      en: {
        heading: `Technology stack overview:`
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";

.technologies-item {
  width: 50%;
  margin: 0 0 20px 0;
  @include breakpoint(md) {
    width: 25%;
  }
}
.technologies-block {
  width: 100%;
  @include breakpoint(md) {
    width: auto;
  }
}
.technologies-image {
  width: 76px;
  font-size: 10px;
  img {
    height: 65px;
    width: 65px;
  }
}
.technologies-name {
  font-size: $font-size-medium;
  font-weight: $font-weight-medium;
  line-height: $line-height-small;
}
.technologies-title {
  span {
    padding-left: 3px;
    text-transform: uppercase;
    font-size: 15px;
  }
}
</style>
