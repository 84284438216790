import ANDROID from "@/assets/img/story/technology/story-android.svg";
import DOCKER from "@/assets/img/story/technology/story-docker.svg";
import LARAVEL from "@/assets/img/story/technology/story-laravel.svg";
import REACT from "@/assets/img/story/technology/story-reactnative.svg";
import VUE from "@/assets/img/story/technology/story-vue.svg";
import UNREAL from "@/assets/img/story/technology/story-unreal.svg";
import OCULUS from "@/assets/img/story/technology/story-oculus.svg";

export const android = {
  src: ANDROID,
  alt: "Android",
  name: "Android"
};
export const docker = {
  src: DOCKER,
  alt: "Docker",
  name: "Docker"
};
export const laravel = {
  src: LARAVEL,
  alt: "Laravel",
  name: "Laravel"
};
export const react = {
  src: REACT,
  alt: "React",
  name: "React Native"
};

export const vue = {
  src: VUE,
  alt: "Vue",
  name: "Vue.js"
};
export const oculus = {
  src: OCULUS,
  alt: "Oculus Quest",
  name: "Oculus Quest"
};
export const unreal = {
  src: UNREAL,
  alt: "Unreal Engine",
  name: "Unreal Engine"
};
