<template>
  <div class="mb-3">
    <div class="video-container mb-1" :style="containerStyle">
      <div class="inner-wrap">
        <iframe
          :src="`https://player.cloudinary.com/embed/?` + createQuery"
          width="100%"
          height="100%"
          scrolling="no"
          allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
          allowfullscreen
          frameborder="0"
        ></iframe>
      </div>
    </div>
    <div class="story-video-subtitle">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "VisualizationVideo",
  props: {
    publicId: {
      type: String,
      required: true
    },
    aspectRatio: {
      type: Number,
      default: 1080 / 1920
    }
  },
  computed: {
    createQuery() {
      const params = [
        `public_id=/${this.publicId}`,
        `cloud_name=mediaformat`,
        `player[fluid]=true`,
        `player[controls]=true`,
        `player[control_bar][volume_panel]=false`,
        `player[colors][accent]=#05a4ff`,
        `player[show_jump_controls]=true`,
        `player[hide_context_menu]=true`,
        `player[floating_when_not_visible]=false`,
        `player[logo_onclick_url]=https://mediaformat.de`,
        `player[logo_image_url]=https://res.cloudinary.com/mediaformat/image/upload/v1590245769/mediaformat-website/c7ee2705-bad0-4381-a8b7-c504c6f26958.jpg`,
        `source[source_types][0]=mp4`,
        `source[source_types][1]=webm`
      ];
      return params.join("&");
    },

    containerStyle() {
      return {
        paddingTop: `${this.aspectRatio * 100}%`
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";

.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.inner-wrap {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.story-video-subtitle {
  font-size: $font-size-sm;
}
</style>
