<template>
  <div
    class="item-wrapper"
    @mouseenter="mouse = true"
    @mouseleave="mouse = false"
    @click="mouse = !mouse"
  >
    <div class="item-container position-relative mb-3">
      <div class="item-img d-block position-relative">
        <div
          :style="`width: ${position}%;`"
          ref="timer"
          class="timer position-absolute text-center"
        />
        <transition name="fade">
          <img
            style="user-select: none"
            class="w-100"
            :key="current"
            :src="image"
            :alt="alt"
          />
        </transition>
      </div>
      <transition name="slide-left">
        <div
          v-if="canLeft"
          @click="previous"
          class="item-container-left d-block position-absolute"
        ></div>
      </transition>
      <div v-if="text" class="item-text">
        <transition name="fade">
          <div class="slide-wrap" :key="current">
            <h3 class="m-0" v-html="title"></h3>
            <div v-html="body"></div>
          </div>
        </transition>
      </div>
      <transition name="slide-right">
        <div
          v-if="canRight"
          @click="next"
          class="item-container-right d-block position-absolute"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryItem",
  props: {
    galleryImages: {
      type: Array,
      default: () => []
    },
    galleryCurrent: {
      type: Number,
      default: 0
    },
    text: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: null,
      body: null,
      image: null,
      alt: null,
      current: null,
      position: 0,
      interval: null,
      mouse: false
    };
  },
  computed: {
    count() {
      return this.galleryImages.length;
    },
    canLeft() {
      return this.current !== 0;
    },
    canRight() {
      return this.current !== this.count - 1;
    }
  },
  methods: {
    previous() {
      if (this.canLeft) {
        this.current -= 1;
        this.render(this.current);
      }
    },
    next() {
      if (this.canRight) {
        this.current += 1;
      } else {
        this.current = 0;
      }
      this.render(this.current);
    },
    gallery(current) {
      return this.galleryImages[current];
    },
    render(current) {
      this.title = this.gallery(current).title;
      this.body = this.gallery(current).body;
      this.image = this.gallery(current).image;
      this.alt = this.gallery(current).alt;
    }
  },
  mounted() {
    this.render(this.galleryCurrent);
    this.current = this.galleryCurrent;

    if (this.galleryImages.length > 1) {
      this.interval = setInterval(() => {
        if (this.mouse) return;
        if (this.position < 100) {
          this.position += 1;
        } else {
          this.next();
          this.position = 0;
        }
      }, 100);
    }
  },
  destroyed() {
    clearInterval(this.interval);
  },
  watch: {
    current(v) {
      if (v === 0 || v === this.count - 1) {
        this.mouse = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";

::v-deep h3 {
  color: $primary;
  font-weight: $font-weight-light;
  white-space: pre;
  @include breakpoint(md) {
    font-size: $font-size-base;
  }
  @include breakpoint(lg) {
    font-size: $font-size-lg;
  }
}

::v-deep strong {
  display: inline-block;
  margin: 0 0 10px 0;
  &.long-word {
    display: block;
  }
  @include breakpoint(md) {
    margin: 0 0 10px 0;
    &.long-word {
      display: inline-block;
    }
  }
  @include breakpoint(lg) {
    margin: 0 0 20px 0;
  }
}

.item-container {
  transition: all 0.2s ease-in-out;
  user-select: none;
  &:hover {
    cursor: pointer;
    box-shadow: $box-gallery-hover;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  position: absolute;
}

/* Arrows animation */
.slide-left-enter-active {
  transition: all 0.4s ease-in-out;
}
.slide-left-leave-active {
  transition: all 0.4s ease-in-out;
}
.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(-100px) scaleX(-1);
  opacity: 0;
}

.slide-right-enter-active {
  transition: all 0.4s ease-in-out;
}
.slide-right-leave-active {
  transition: all 0.4s ease-in-out;
}
.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.item-container-right,
.item-container-left {
  content: "";
  top: 320px;
  background-size: 70%;
  opacity: 0.6;
  width: 100px;
  height: 190px;
  &:hover {
    opacity: 1;
  }
}
.item-container-left {
  left: -250px;
  transform: scaleX(-1);
  background: url("../../assets/img/mf3d/arrow-3d.svg") no-repeat;
}

.item-container-right {
  right: -250px;
  background: url("../../assets/img/mf3d/arrow-3d.svg") no-repeat;
}

.timer {
  bottom: 0;
  left: 0;
  background-color: $accent;
  height: 5px;
  width: 100%;
  transition: width 0.1s linear;
}
@keyframes timeline {
  0% {
    width: 0%;
    background: $accent;
  }
  50% {
    width: 50%;
    background: $accent;
  }
  100% {
    width: 100%;
    background: $accent;
  }
}
.item-text {
  user-select: none;
  box-sizing: border-box;
  background-color: $body-bg-dark;
  font-size: $font-size-sm;
  color: $text-color-bright;
  padding: 20px;
  @include breakpoint(md) {
    padding: 30px;
  }
  @include breakpoint(lg) {
    padding: 50px 40px;
  }
}
.item-wrapper {
  margin: 0;
  @include breakpoint(md) {
    margin: 0 10%;
  }
  @include breakpoint(lg) {
    margin: 0 25%;
  }
}
</style>
