<template>
  <WhitePageLayout
    :page-icon="pageIcon"
    :title="$t('title')"
    :subtitle="$t('descr')"
  >
    <div class="container">
      <div class="v-grid ">
        <div class="col-2 col-offset">
          <StoryText>
            <template #title>
              <div v-html="$t('texts.0.title')"></div>
            </template>
            <div v-html="$t('texts.0.text')"></div>
          </StoryText>
          <StoryTechnologies :technologiesProps="technologiesArray" />
        </div>
      </div>
    </div>
    <div class="container" v-if="galleryImages.length">
      <GalleryItem
        class="mb-3"
        :galleryImages="galleryImages"
        :galleryCurrent="0"
        :text="false"
      />
    </div>

    <div class="container">
      <div class="v-grid ">
        <div class="col-2 col-offset">
          <StoryText>
            <template #title>
              <div v-html="$t('texts.1.title')"></div>
            </template>
            <div v-html="$t('texts.1.text')"></div>
          </StoryText>
          <VisualizationVideo
            public-id="mediaformat-website/gewst-visualization.mp4"
            >{{ $t("creation") }}</VisualizationVideo
          >
          <StoryText>
            <template #title>
              <div v-html="$t('texts.2.title')"></div>
            </template>
            <div v-html="$t('texts.2.text')"></div>
          </StoryText>
        </div>
      </div>
    </div>
  </WhitePageLayout>
</template>

<script>
//
import GalleryItem from "@/components/basic/GalleryItem";
//
import StoryTechnologies from "@/components/story/StoryTechnologies";
import StoryText from "@/components/story/StoryText";
import VisualizationVideo from "@/components/story/VisualizationVideo";

import { android, docker, laravel, react } from "@/utils/technologyImages";
import { metaInfo } from "@/utils/titleHelper";
import WhitePageLayout from "@/components/layouts/WhitePageLayout";
import pageIcon from "@/assets/img/logo/binary.svg";

import galleryImage0 from "@/assets/img/story/story1_0.png";
import galleryImage1 from "@/assets/img/story/story1_1.png";
import galleryImage2 from "@/assets/img/story/story1_2.png";

export default {
  name: "StoryDigitalisierungImHandwerk",
  components: {
    WhitePageLayout,
    VisualizationVideo,
    StoryTechnologies,
    StoryText,
    GalleryItem
  },
  data() {
    return {
      galleryImages: [],
      pageIcon,
      technologiesArray: [android, docker, laravel, react]
    };
  },
  async created() {
    this.galleryImages = [
      { image: galleryImage0 },
      { image: galleryImage1 },
      { image: galleryImage2 }
    ];
  },
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },

  i18n: {
    messages: {
      de: {
        title: "Schrittweise zum digitalen Produkt",
        descr:
          "Mit smarter Technologie, zeitaufwendige Arbeitsschritte optimieren und beschleunigen.",
        creation: `Entstehungsverlauf der App.`,
        texts: [
          {
            title: "Projekt",
            text: `<p class="mb-1">
            <strong
              >Die Entwicklung eines hochwertigen, digitalen Produkts erfordert
              überlicherweise einen hohen Ressourceneinsatz.</strong
            >
            Im Worst Case wird an den Bedürfnissen des Nutzers vorbeientwickelt,
            viel Zeit und Geld investiert. In unserer
            <strong>Success-Story 01</strong> ermöglichte ein Minimum Viable
            Product „MVP“ eine zielgruppenorientierte Produktentwicklung, die
            schrittweise und mit überschaubarem Aufwand zum Erfolg führte.
          </p>

          <p>
            Die Entwicklung wurde durch Nutzerbefragungen und Usability Tests
            begleitet. Aus der Analyse der wertvollen Feedbackrunden wurden
            wichtige Erkenntnisse gewonnen und im MVP entsprechend umgesetzt.
          </p>`
          },
          {
            title: "Herausforderung",
            text: `<p class="mb-3">
            <strong
              >Zeitaufwendige und dokumentationspflichtige
              Arbeitsschritte</strong
            >
            im Unternehmen sollten mittels Individual- Software beschleunigt und
            optimiert werden. Dabei sollte der komplexe Planungs- und
            Kalkulationsprozess für die Elektroplanung zusammengeführt und
            vereinfacht werden. Für die Arbeit auf Baustellen beim Kunden vor
            Ort, war auch die Offlineverfügbarkeit der Applikation von großer
            Relevanz.
          </p>`
          },
          {
            title: "Ergebnis",
            text: `<p class="mb-1">
            <strong class="d-block"
              >Der Prozess war für alle Beteiligten ein voller Erfolg.</strong
            >
            Die Applikation ermöglicht dem Elektromeister die Planung und
            zeitgleiche Dokumentation einer Elektroinstallation beim Kunden
            direkt vor Ort. Durch die teils automatisierte Angebotskalkulation
            wird zusätzlich wertvolle Arbeitszeit im Büro eingespart. Die
            Entwicklung der Betriebs-App wird in weiteren Phasen fortgesetzt.
          </p>
          <p class="mb-3">
            Falls Sie mehr über dieses Projekte erfahren möchten, dann laden wir
            Sie herzlichst zu uns nach Kaiserslautern ein:
          </p>`
          }
        ]
      },
      en: {
        title: "Towards a digital product",
        descr:
          "Optimize and speed up time-consuming processes with smart technology",
        creation: `App development`,
        texts: [
          {
            title: "Project",
            text: `<p class="mb-1">When developing <strong>high-quality digital products</strong>, you usually <strong>need to invest a significant amount of time and money</strong>. If the development process is not focused on the needs of the user, your invested time and money can go to waste in a worst-case scenario. In our <strong>Success Story 01</strong>, we worked towards a Minimum Viable Product (MVP) and focused our product development on a well-defined target group and their needs. This achieved success with a reasonable and manageable investment.</p>

          <p>We focused our development based on the results of user surveys and usability tests. The valuable feedback gained from the users provided insights that guided our development towards the MVP.</p>`
          },
          {
            title: "The challenge",
            text: `<p class="mb-3">To speed up and optimize the <strong>time-consuming and documentation-heavy steps in company processes</strong> using custom software. Our aim was to combine and simplify the complex planning and estimation stages in electrical work. The application was required to operate offline in order to support electrical work carried out at customers’ construction sites.</p>`
          },
          {
            title: "The result",
            text: `<p class="mb-1"><strong>The project was a complete success.</strong> The developed application enables the master electrician to plan and simultaneously document an electrical installation directly on site with the customer. Partially automated quotation calculations save valuable time. Further development in stages is planned for the operations app. </p>
          <p class="mb-3">If you would like to learn more about this project, please visit us in Kaiserslautern:</p>`
          }
        ]
      }
    }
  }
};
</script>
